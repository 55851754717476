<template>
  <div class="conditions-view">
    <preloader />
    <section class="section p-l-0 p-r-0 p-t-80">
      <logo />
      <h2 class="section__title">
        TERMENI ȘI CONDIȚII
      </h2>

      <div class="conditions">
        <h3 class="conditions__subtitle">
          „X RACE SHOWDOWN” [www.frankcasino.ro]<br>
          „X RACE SHOWDOWN” [www.slotv.ro]<br>
          „X RACE SHOWDOWN” [www.mrbit.ro]<br>
          [Promoția; Campania promoțională]
        </h3>
        <p>
          Acesti Termeni si Conditii se aplica Promotiei Frankcasino / MrBit /SlotV „[X Race
          Showdown]” [Promotia] organizata de catre <strong>Avento MT Limited</strong>, o companie
          organizata si care functioneaza conform legilor din Malta, avand sediul in Malta,
          Level 6, Ta Xbiex Business Centre, Triq Testaferrata, Ta’ Xbiex, XBX 1043 [„Avento”,
          „noi”, „noua” sau „Compania”] si de catre <strong>Playattack Limited</strong>, o companie
          organizata si care functioneaza conform legilor din Malta, avand sediul in Malta,
          Level 4B, TA’, Xbiex Business Centre, Testaferrata Street, Ta’ Xbiex XBX
          1403[„Playattack”], in calitate de reprezentant al Companiei.
        </p>
        <p>
          Compania este licentiata ca organizator de jocuri de noroc la distanta de catre Oficiul National pentru Jocuri
          de Noroc, conform licentei nr. L1213854W001295, valabila pana la data de 31.05.2031.
        </p>
        <p>
          Playattack este licentiata pentru furnizarea de servicii de afiliere de catre Oficiul National pentru Jocuri
          de
          Noroc, conform Deciziei nr. 1421/29.07.2021, valabila pana la data de 31.08.2031.
        </p>
        <h3 class="conditions__subtitle">
          1. Aspecte generale
        </h3>
        <p>
          Promotia va incepe in data de <strong>30.09.2024</strong> ora 00:00 ora Romaniei si se va incheia la data de
          <strong>30.10.2024</strong> ora 23:59 ora Romaniei [„Perioada Promotiei”].
        </p>
        <p>
          Cu toate acestea, ne rezervam dreptul de a inceta Promotia inainte de sfarsitul Perioadei Promotiei sau de a
          modifica datele acesteia, cu notificarea prealabila a Participantilor si unde este cazul a Oficiului National
          pentru Jocuri de Noroc, inclusiv dar fara limitare – in acele cazuri cand aceasta se impune din motive de
          securitate, siguranta publica si/sau sanitara, pentru a ne conforma unei cerinte legale sau a unei autoritati
          de
          reglementare, din cauza unui abuz sau din orice alt motiv care face ca Promotia sa nu mai poata fi realizata
          in
          conditiile avute in vedere, ca rezultat al unor circumstante exceptionale.
        </p>
        <p>
          Daca va inscrieti pentru a participa la Promotie, veti fi considerat prin aceasta a fi consimtit in mod expres
          si neconditionat la Termenii si Conditiile care guverneaza aceasta Promotie, precum si la prevederile
          Termenilor
          si Conditiilor Generale Avento, Politica de Bonus, Politica de confidentialitate si Politicile de
          Joc Responsabil (si a tuturor politicilor si procedurilor care fac parte din acestea).
        </p>
        <h3 class="conditions__subtitle">
          2. Conditii de eligibilitate pentru jucatori
        </h3>

        <ol class="conditions-list m-b-20">
          <li class="conditions-list__item">
            Promotia se adreseaza jucatorilor Avento inregistrati pe fiecare dintre website-urile
            <a
              target="_blank"
              href="https://frankcasino.ro"
              class="link-warning"
            >www.frankcasino.ro</a>,
            <a
              target="_blank"
              href="https://mrbit.ro"
              class="link-warning"
            >www.mrbit.ro</a>,
            <a
              target="_blank"
              href="https://slotv.ro"
              class="link-warning"
            >www.slotv.ro</a> [brand-uri] si care au indeplinit procedura de verificare a contului de joc [kyc], fie
            inainte de inceperea Promotiei, sau pana la finalul acesteia. Jucatorii care vor finaliza procedura de
            verificare a contului dupa expirarea Promotiei nu vor putea fi considerati ca facand parte din Promotie,
            fiind
            exclusi „de facto”.
          </li>
          <li class="conditions-list__item">
            <p>
              Sunt eligibile pentru Promotie jocurile de tip cazinou ale urmatorilor furnizori de jocuri:
              [Pateplay, NLC, Amusnet, Pragmatic Play, Greentube].
            </p>
          </li>
          <li class="conditions-list__item">
            <p>
              Cu exceptia celor prevazute la alin (2) de mai sus, niciun alt joc de tip cazinou de la niciun alt
              furnizor
              de jocuri <strong>nu participa la Promotie, nu genereaza puncte si nu contribuie</strong>
              la realizarea clasamentului sau la cresterea numarului de puncte al echipei.
            </p>
          </li>
          <li class="conditions-list__item">
            <p>
              Pentru a se inscrie in Promotie, jucatorii trebuie sa se inregistreze pe pagina Promotiei „X Race
              Showdown”,
              ce poate fi accesata la adresa www.theshowdown.ro [Pagina Promotiei]. Pagina Promotiei apartine
              organizatorilor prezentei Campanii Promotionale si va fi folosita exclusiv pentru inregistrarea
              Participantilor si pentru publicarea clasamentului Promotiei, sau a oricaror noutati cu privire la
              Promotie.
              Pagina Promotiei nu afiseaza si nu va afisa continut cu privire la jocurile de noroc, cu exceptia celor cu
              titlu informativ privitor la Campania Promotionala si nici nu va permite desfasurarea oricaror activitati
              specifice jocurilor de noroc.
            </p>
          </li>
          <li class="conditions-list__item">
            <p>
              La inscriere pe Pagina Promotiei, fiecare jucator va furniza adresa(ele) de e-mail cu care este
              inregistrat
              ca si jucator pe website-urile
              <a
                target="_blank"
                href="https://frankcasino.ro"
                class="link-warning"
              >www.frankcasino.ro</a>,
              <a
                target="_blank"
                href="https://mrbit.ro"
                class="link-warning"
              >www.mrbit.ro</a>
              sau
              <a
                target="_blank"
                href="https://slotv.ro/ro"
                class="link-warning"
              >www.slotv.ro</a>.
            </p>
            <p>
              De asemenea, fiecare participant va trebui sa opteze pentru care dintre brand-uri va dori sa ii fie
              atribuit
              bonusul, la final de campanie, inclusiv pentru cazurile in care un jucator detine cate un cont de joc pe
              mai
              mult decat un brand al Companiei;
            </p>
          </li>
          <li class="conditions-list__item">
            <p>
              Tot la inregistrarea in Promotie, fiecare jucator va trebui sa selecteze un Promotor al Campaniei, din
              lista
              prezentata pe Pagina Promotiei, fiind astfel atribuit unei echipe. Lista cu Promotorii Campaniei se
              regaseste pe Pagina Promotiei.
            </p>
          </li>
          <li class="conditions-list__item">
            <p>
              Jucatorii inscrisi isi pot schimba echipa de Promotor din care fac parte, pe durata Campaniei, doar o
              singura data. Daca un jucator opteaza pentru schimbarea Promotorului, punctele acumulate pana la acel
              moment
              vor fi anulate, iar contorizarea punctelor va incepe de la zero pentru jucatorul respectiv. Pentru a
              schimba
              echipele, jucatorul trebuie sa transmita un e-mail la urmatoarea adresa de mail:
              <a
                class="link-warning"
                href="mailto:theshowdown@playattack.com"
              >
                theshowdown@playattack.com
              </a>
              si sa furnizeze urmatoarele
              informatii: numele de utilizator de pe site-ul web al concursului si echipa la care doreste sa se inscrie.
            </p>
          </li>
          <li class="conditions-list__item">
            <p>
              Pentru a participa la Promotie, jucatorii inscrisi trebuie sa detina fonduri suficiente pe contul de joc,
              alcatuite din bani reali sau bani bonus. Nu exista conditii minime de depunere. Eventualele sume bonus
              castigate pe durata Promotiei vor fi avute in vedere la calculul punctelor. Fondurile alcatuite din bani
              reali provenind din eventuale sume bonus pentru care au fost indeplinite conditiile de rulaj vor fi luate
              in
              considerare la calculul punctelor
            </p>
            <p>
              Pentru alte campanii bonus aflate in derulare pe durata Campaniei Promotionale si accesate de catre
              participanti, acestia vor trebui sa respecte Termenii si Conditiile specifice campaniei respective, in
              ceea
              ce priveste conditiile de rulaj, depunere etc..
              Termenii altor campanii de bonus aflate in derulare in acelasi timp cu prezenta Promotie nu se completeaza
              cu cei ai Promotiei si invers
            </p>
          </li>
          <li class="conditions-list__item">
            <p>
              Pe durata Promotiei, jucatorii vor putea sa retraga eventualele sume in bani reali acumulate pe contul de
              joc, in conditiile stipulate in Termenii si Conditii Generale, Politicilor generale de bonus sau ale
              promotiilor specifice de bonus accesate de jucator.
            </p>
          </li>
          <li class="conditions-list__item">
            <p>
              Punctele acumulate conform mecanismului Promotiei nu pot fi retrase sau considerate ca fiind bani reali.
            </p>
          </li>
        </ol>
        <h3 class="conditions__subtitle">
          3. Conditii de eligibilitate pentru Promotor(i):
        </h3>
        <ol class="conditions-list m-b-20">
          <li class="conditions-list__item">
            <p>
              Promotorii sunt persoane fizice, cetateni romani cu domiciliul si/sau resedinta in Romania, ce detin un
              cont
              valid deschis pe platformele YouTube sau Twitch, altii decat jucatorii inscrisi in Promotie si care au
              implinit varsta de 18 ani, sau varsta necesara pentru implicarea in activitati de jocuri de noroc, cel mai
              tarziu la momentul inscrierii in programul de marketing al Playattack. Orice Promotor trebuie sa detina o
              licenta Clasa 2 valida, emisa de ONJN pe toata durata de desfasurare a Promotiei.
            </p>
          </li>
          <li class="conditions-list__item">
            <p>
              Promotorii nu pot participa la Promotie in calitate de jucatori. In cazul in care vor fi identificate
              persoane care participa la Promotie direct sau prin interpusi si detin o dubla calitate, Promotor inscris
              si
              jucator, acestea vor fi excluse din Campanie, iar punctele acumulate in calitate de jucator vor fi
              anulate.
              In ceea ce priveste calitatea de Promotor, persoana supusa conditiilor prezentului alineat, aceasta va fi
              exclusa din Promotie si in aceasta calitate, jucatorii din echipa sa fiind distribuiti automat urmatoarei
              echipe cu punctajul cel mai apropiat. Punctele acumulate de catre jucatorii repectivi se vor mentine.
            </p>
          </li>
          <li class="conditions-list__item">
            <p>
              Pentru a participa la Promotie, exclusiv conform mecanismului descris prin acesti Termeni si Conditii,
              Promotorii trebuie sa fie inscrisi in programul de marketing al Playattack, disponibil la
              www.theshowdown.ro, iar contul de Promotor sa fi fost verificat de catre Playattack si declarat ca fiind
              eligibil. Conditiile de verificare a unui cont de Promotor nu fac obiectul prezentului regulament,
              fiind o componenta a contractului existent intre Promotor si Playattack.
            </p>
          </li>
          <li class="conditions-list__item">
            <p>
              Imediat ce un Promotor a fost verificat si declarat ca fiind eligibil de catre Playattack, acesta va fi
              inscris in lista Promotorilor participanti.
            </p>
          </li>
          <li class="conditions-list__item">
            <p>Obligatiile Promotorilor constau in:</p>
            <ul class="conditions-list list-style-disc p-l-15">
              <li class="conditions-list__item p-l-0">
                <p>
                  promovarea Campaniei pe canalele de YouTube si Twich pe care isi au create conturile personale de
                  marketing, cu respectarea dispozitiilor legale in vigoare. Este strict interzisa promovarea premiilor,
                  bonusurilor sau inserarea unor link-uri de directionare, precum si inserarea din partea Promotorilor a
                  oricaror comentarii personale cu privire la premii, bonusuri;
                </p>
              </li>
              <li class="conditions-list__item p-l-0">
                <p>
                  Promotorii vor putea sa isi incurajeze echipa pe care o conduc, formata din jucatorii inscrisi la
                  Promotorul respectiv, in vederea acumularii de cat mai multe puncte pe durata Promotiei, cu
                  respectarea
                  dispozitiilor legale in vigoare si a indicatiilor prevazute in contractului existent intre Promotor si
                  Playattack si a dispozitiilor transmise pe orice cale de catre Playttack. Promotorilor le este strict
                  interzisa utilizarea de incurajari care pot incalca principiul de joc responsabil, incluzand, dar fara
                  a se limita la, solicitari directe adresate jucatorilor pentru a depune mai multe fonduri in contul
                  de joc;
                </p>
              </li>
              <li class="conditions-list__item p-l-0">
                <p>
                  insemnele +18, ONJN si seria Licentei vor fi afisate in mod corespunzator.
                </p>
              </li>
            </ul>
          </li>
          <li class="conditions-list__item">
            <p>
              Numarul de Promotori nu este limitat, dar premiile se vor acorda conform
              Termenilor si Conditiilor Promotiei, doar pentru echipele clasate pe primele 10 (zece)
              locuri (clasamentul pe echipe)
            </p>
            <p>
              Pentru echipele clasate pe urmatoarele locuri (11, 11+), acestea doar vor fi mentionate in clasament, fara
              a
              beneficia de vreun premiu, atat ca echipa cat si ca jucatori ai echipelor respective.
            </p>
          </li>
        </ol>
        <h3 class="conditions__subtitle">
          4. Conditii generale de eligibilitate
        </h3>
        <p>Participarea la Promotie este supusa urmatoarelor conditii:</p>
        <ol class="conditions-list m-b-20">
          <li class="conditions-list__item">
            <p>
              Nu este permisa participarea la Promotie niciunei persoane sub varsta de 18 ani, sau sub varsta necesara
              pentru implicarea in activitati de jocuri de noroc oferite prin intermediul website-urilor
              <a
                target="_blank"
                href="https://frankcasino.ro"
                class="link-warning"
              >www.frankcasino.ro</a>,

              <a
                target="_blank"
                href="https://mrbit.ro"
                class="link-warning"
              >www.mrbit.ro</a>,

              <a
                target="_blank"
                href="https://slotv.ro"
                class="link-warning"
              >www.slotv.ro</a>,
              conform oricarei jurisdictii relevante, relevant fiind pragul de varsta mai mare. Va putem solicita in
              orice
              moment sa ne furnizati dovada varstei si/sau a identitatii.
            </p>
          </li>
          <li class="conditions-list__item">
            <p>
              Din motive de securitate si verificare, ne rezervam dreptul de a limita participarea la aceasta Promotie
              pentru un singur participant per gospodarie, terminal sau dispozitiv mobil, sub rezerva conditiilor de
              eligibilitate de la Cap. 2.
            </p>
          </li>
          <li class="conditions-list__item">
            <p>
              Ne rezervam dreptul de a va revoca dreptul de a participa si/sau de a continua sa participati la aceasta
              Promotie in cazul in care faceti subiectul oricarei restrictii, suspendari sau limitari a dreptului dvs de
              a
              accesa oricare dintre cele trei website-uri sau de a participa la jocurile oferite de Companie pe
              website-uri. In acest caz, va vor fi retrase orice bonusuri si/sau orice alte beneficii pe care le-ati
              primit, sau pe care ati fi fost eligibil sa le primiti in cadrul acestei Promotii.
            </p>
          </li>
          <li class="conditions-list__item">
            <p>
              Un jucator se poate inscrie pentru a participa la Promotie o singura data, pentru fiecare brand in parte.
            </p>
          </li>
          <li class="conditions-list__item">
            <p>
              Sunt eligibile a participa la Promotie numai persoane cu domiciliul sau resedinta in Romania
              [„Jurisdictia Eligibila”].
            </p>
          </li>
          <li class="conditions-list__item">
            <p>
              Sunt eligibile a participa la Promotie numai persoane care au calitatea de membru inregistrat [jucator] si
              care detin (si mentin pe toata durata Promotiei) un cont activ pe oricare dintre website-uri, cont ce a
              facut obiectul si a fost validat din punct de vedere al procedurilor de cunoastere a clientelei (kyc)
              conform prevederilor art. 2.1.
            </p>
          </li>
        </ol>
        <h3 class="conditions__subtitle">
          5. Desfasurarea Promotiei
        </h3>
        <ol class="conditions-list m-b-20">
          <li class="conditions-list__item">
            <p>Modalitatea de calculare si acumulare a punctelor de catre jucatori:</p>
            <p>
              Fiecare jucator genereaza puncte atunci cand obtine un castig la oricare dintre jocurile de tip slot
              inscrise in Promotie. Valoarea unui punct se calculeaza dupa formula: suma castigata per runda / valoarea
              mizei plasate pentru runda castigatoare = nr. puncte<sup class="text-danger font-16">*</sup>  [rezultat],
              cu respectarea cumulativa a conditiilor de mai jos:
            </p>
            <p class="font-12">
              <sup class="text-danger font-16">*</sup> Vor fi luate in calcul doar rundele in care pariul are valoarea
              minima de 1 RON. Daca pariul are valoarea mai mica de <strong>1 RON</strong>, punctele respective nu vor
              fi luate in calcul si nici atribuite jucatorului.
            </p>
            <p class="font-12">
              <sup class="text-danger font-16">**</sup> Vor fi luate in considerare doar rezultatele ≥ 50. Daca prin
              aplicarea formulei la o runda castigatoare, rezultatul este mai mic de 50, punctele respective nu vor fi
              luate in calcul si nici atribuite jucatorului.
            </p>
          </li>
          <li class="conditions-list__item">
            <p>
              Este interzis ca un jucator inscris in Promotie sa joace in acelasi timp doua sau mai multe jocuri de tip
              slot. Pentru astfel de situatii, rezultatul celui de al doilea joc sau a urmatoarelor nu va fi avut in
              vedere la calculul punctelor.
            </p>
          </li>
          <li class="conditions-list__item">
            <p>
              Pentru situatiile cand jucatorii doresc sa cumpere runde gratuite, valoarea unui punct se va calcula
              conform formulei de la aliniatul de mai sus.
            </p>
            <p>
              <span class="text-gray">Exemplu:</span> daca un jucator achizitioneaza runde conform Termeni si Conditii
              Generali cu o suma de 40 RON, plaseaza miza de 4 RON si inregistreaza un castig de 4.000 ron, valoarea
              punctelor castigate este de: 4.000 RON suma castigata / 40 RON = 100 puncte
              <sup class="text-danger font-16">*</sup>
            </p>
            <p class="font-12">
              <sup class="text-danger font-16">*</sup> sub rezerva conditiilor de la alineatul (1)
            </p>
          </li>
          <li class="conditions-list__item">
            <p>
              Distributia punctelor si a premiilor pe echipe:
            </p>
            <div class="small-viewport">
              <table class="conditions__table">
                <tr>
                  <td>Clasament pe echipe</td>
                  <td>Puncte pe echipa</td>
                  <td>Premiu per echipa / RON</td>
                </tr>
                <tr>
                  <td>1</td>
                  <td>x</td>
                  <td>31500</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>x</td>
                  <td>24500</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>x</td>
                  <td>21000</td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>x</td>
                  <td>19250</td>
                </tr>
                <tr>
                  <td>5</td>
                  <td>x</td>
                  <td>17500</td>
                </tr>
                <tr>
                  <td>6</td>
                  <td>x</td>
                  <td>15750</td>
                </tr>
                <tr>
                  <td>7</td>
                  <td>x</td>
                  <td>14000</td>
                </tr>
                <tr>
                  <td>8</td>
                  <td>x</td>
                  <td>12250</td>
                </tr>
                <tr>
                  <td>9</td>
                  <td>x</td>
                  <td>10500</td>
                </tr>
                <tr>
                  <td>10</td>
                  <td>x</td>
                  <td>8750</td>
                </tr>
              </table>
            </div>
            <p>
              Daca numarul echipelor / Promotorilor va fi mai mic decat 10,
              clasamentul se va considera in ordinea locurilor.
            </p>
            <p>
              <span class="text-gray">Exemplu:</span> daca vor exista numai 5 echipe, premiile se vor considera cele de
              la
              pozitiile 1-5. Valorile mentionate la pozitiile 6-10 nu se vor redistribui
            </p>
            <p class="font-12">
              <sup class="text-danger font-16">*</sup>
              Valoarea premiilor se va aloca exclusiv jucatorilor, Promotorii neputand accesa premiile respective.
            </p>
            <p>
              La realizarea clasamentului pe echipe se vor aduna punctele acumulate de fiecare jucator in mod individual
              ce face parte din respectiva echipa.
            </p>
          </li>
          <li class="conditions-list__item">
            <p>
              Distributia premiului in cadrul echipei se va efectua in functie de clasamentul jucatorilor si al
              punctelor
              acumulate de fiecare in mod individual
            </p>
            <div class="small-viewport">
              <table class="conditions__table">
                <tr>
                  <td>Clasament in cadrul echipei</td>
                  <td>Procentaj premiu (% din premiul echipei)</td>
                </tr>
                <tr>
                  <td>1</td>
                  <td>18%</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>14%</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>12%</td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>11%</td>
                </tr>
                <tr>
                  <td>5</td>
                  <td>10%</td>
                </tr>
                <tr>
                  <td>6</td>
                  <td>9%</td>
                </tr>
                <tr>
                  <td>7</td>
                  <td>8%</td>
                </tr>
                <tr>
                  <td>8</td>
                  <td>7%</td>
                </tr>
                <tr>
                  <td>9</td>
                  <td>6%</td>
                </tr>
                <tr>
                  <td>10</td>
                  <td>5%</td>
                </tr>
                <tr>
                  <td>11 si urmatoarele</td>
                  <td>Runde gratuite<sup class="text-danger font-16">*</sup></td>
                </tr>
              </table>
            </div>
            <p class="font-12">
              <sup class="text-danger font-16">*</sup>Rundele gratuite se vor acorda jucatorilor clasati pe locurile 11,
              11+,
              conform Politicii generale de bonus, prin oferte personalizate, transmise jucatorilor respectivi.
            </p>
          </li>
          <li class="conditions-list__item">
            <p>
              Pentru situatiile in care mai multi jucatori vor acumula un numar egal de puncte,
              aferente unei pozitii, atunci valoarea premiului pentru pozitia respectiva se va
              imparti in mod egal intre respectivii jucatori, cu exceptia jucatorilor de pe pozitiile
              11, 11+, care vor primi fiecare runde gratuite, conform Politicii generale de bonus
            </p>
          </li>
          <li
            class="conditions-list__item"
          >
            <p>
              Exemplu privind impartirea premiului in cadrul echipei, pentru situatia in care un singur jucator se va
              clasa pentru fiecare dintre pozitiile din clasament:
            </p>
            <div class="small-viewport">
              <table class="conditions__table">
                <tr>
                  <td>Echipa 1 (RON)</td>
                  <td>Echipa 2 (RON)</td>
                  <td>Echipa 3 (RON)</td>
                  <td>Echipa 4 (RON)</td>
                  <td>Echipa 5 (RON)</td>
                  <td>Echipa 6 (RON)</td>
                  <td>Echipa 7 (RON)</td>
                  <td>Echipa 8 (RON)</td>
                  <td>Echipa 9 (RON)</td>
                  <td>Echipa 10 (RON)</td>
                </tr>
                <tr>
                  <td>5670</td>
                  <td>4410</td>
                  <td>3780</td>
                  <td>3465</td>
                  <td>3150</td>
                  <td>2835</td>
                  <td>2520</td>
                  <td>2205</td>
                  <td>1890</td>
                  <td>1575</td>
                </tr>
                <tr>
                  <td>4410</td>
                  <td>3430</td>
                  <td>2940</td>
                  <td>2695</td>
                  <td>2450</td>
                  <td>2205</td>
                  <td>1960</td>
                  <td>1715</td>
                  <td>1470</td>
                  <td>1225</td>
                </tr>
                <tr>
                  <td>3780</td>
                  <td>2940</td>
                  <td>2520</td>
                  <td>2310</td>
                  <td>2100</td>
                  <td>1890</td>
                  <td>1680</td>
                  <td>1470</td>
                  <td>1260</td>
                  <td>1050</td>
                </tr>
                <tr>
                  <td>3465</td>
                  <td>2695</td>
                  <td>2310</td>
                  <td>2117.5</td>
                  <td>1925</td>
                  <td>1732.5</td>
                  <td>1540</td>
                  <td>1347.5</td>
                  <td>1155</td>
                  <td>962.5</td>
                </tr>
                <tr>
                  <td>3150</td>
                  <td>2450</td>
                  <td>2100</td>
                  <td>1925</td>
                  <td>1750</td>
                  <td>1575</td>
                  <td>1400</td>
                  <td>1225</td>
                  <td>1050</td>
                  <td>875</td>
                </tr>
                <tr>
                  <td>2835</td>
                  <td>2205</td>
                  <td>1890</td>
                  <td>1732.5</td>
                  <td>1575</td>
                  <td>1417.5</td>
                  <td>1260</td>
                  <td>1102.5</td>
                  <td>945</td>
                  <td>787.5</td>
                </tr>
                <tr>
                  <td>2520</td>
                  <td>1960</td>
                  <td>1680</td>
                  <td>1540</td>
                  <td>1400</td>
                  <td>1260</td>
                  <td>1120</td>
                  <td>980</td>
                  <td>840</td>
                  <td>700</td>
                </tr>
                <tr>
                  <td>2205</td>
                  <td>1715</td>
                  <td>1470</td>
                  <td>1347.5</td>
                  <td>1225</td>
                  <td>1102.5</td>
                  <td>980</td>
                  <td>857.5</td>
                  <td>735</td>
                  <td>612.5</td>
                </tr>
                <tr>
                  <td>1890</td>
                  <td>1470</td>
                  <td>1260</td>
                  <td>1155</td>
                  <td>1050</td>
                  <td>945</td>
                  <td>840</td>
                  <td>735</td>
                  <td>630</td>
                  <td>525</td>
                </tr>
                <tr>
                  <td>1575</td>
                  <td>1225</td>
                  <td>1050</td>
                  <td>962.5</td>
                  <td>875</td>
                  <td>787.5</td>
                  <td>700</td>
                  <td>612.5</td>
                  <td>525</td>
                  <td>437.5</td>
                </tr>
                <tr>
                  <td>FS Reward</td>
                  <td>FS Reward</td>
                  <td>FS Reward</td>
                  <td>FS Reward</td>
                  <td>FS Reward</td>
                  <td>FS Reward</td>
                  <td>FS Reward</td>
                  <td>FS Reward</td>
                  <td>FS Reward</td>
                  <td>FS Reward</td>
                </tr>
              </table>
            </div>
          </li>
          <li class="conditions-list__item">
            <p>
              Premiile - bonus vor fi acordate pe contul de joc al fiecarui castigator, in termen de 14 zile lucratoare
              de
              la incheierea Promotiei si a verificarii rezultatelor finale.
            </p>
          </li>
          <li class="conditions-list__item">
            <p>
              Fiecare jucator participant la Promotie va putea sa verifice in orice moment numarul de puncte acumulate
              pe
              Pagina Promotiei.
            </p>
          </li>
          <li class="conditions-list__item">
            <p>
              Premiul acordat pe contul de joc va avea valoare bonus si are o conditie de rulaj x3. Conditia de rulaj
              trebuie indeplinita intr-o perioada de 7 zile, de la momentul acordarii Premiului in contul jucatorului.
            </p>
          </li>
          <li class="conditions-list__item">
            <p>
              Perioada maxima de promovare de catre un Promotor (durata streaming) nu poate depasi 23:59/24:00 ore.
            </p>
          </li>
          <li class="conditions-list__item">
            <p>Actualizarea clasamentului se va efectua la fiecare 15 minute.</p>
          </li>
          <li class="conditions-list__item">
            <p>
              Toti jucatorii castigatori sunt considerati a ne acorda noua si ne acorda (atat noua cat si afiliatilor
              nostri) prin participarea la Promotie o licenta exclusiva, perpetua, gratuita, irevocabila, la nivel
              global
              pentru a le folosi numele, numele contului de joc, raspunsurile si infatisarea / imaginea precum si pentru
              a reproduce, replica, distribui, afisa public, executa public si efectua orice lucrari derivate bazate pe
              fotografiile si inregistrarile video / audio a acestora realizate de noi si/sau imputernicirii nostri in
              timpul sau in legatura cu Activitatile promotionale, interviuri cu castigatorii etc., in orice mediu
              online
              sau offline (indiferent daca este cunoscut acum s-au dezvoltat in viitor si incluzand – dar fara a fi
              limitat la – televizor, radior, internet, webcasts, social media, cum ar fi – fara limitare – Twiter,
              Instagram, Facebook, Telegram). De asemenea, toti jucatorii declarati participanti la Promotie si
              declarati
              castigatori renunta ca efect al participarii la Promotie la orice pretentie impotriva noastra si/sau a
              afiliatilor nostri derivand din cele de mai sus.
            </p>
            <p>
              Fiecare jucator inscris in Promotie va trebui sa semneze un Formular de renuntare si descarcare de
              raspundere a Companiei inainte de a participa la Activitatile Promotionale si ca o conditie a participarii
              sale (si a primirii de catre acesta de beneficii acordate in cadrul acestei Promotii).
            </p>
          </li>
        </ol>
        <h3 class="conditions__subtitle">
          6. Premiile
        </h3>
        <ol class="conditions-list m-b-20">
          <li class="conditions-list__item">
            <p>
              Premiul nu se acorda decat in modalitatile mentionate anterior. Premiile se vor acorda pe contul de joc,
              doar dupa verificarea identitatii jucatorilor si asigurarea ca procedura kyc a fost parcursa
              corespunzator.
            </p>
          </li>
          <li class="conditions-list__item">
            <p>
              Compania nu va avea nicio obligatie de acordare a Premiului catre niciun castigator, in ipoteza in care
              acesta a incalcat procedura de kyc, prezentul Regulament, Termenii si Conditii Generale sau legislatia
              aplicabila.
            </p>
          </li>
          <li class="conditions-list__item">
            <p>
              Premiul nu se va acorda prin echivalent banesc. In cazul in care un castigator nu poate (din orice motiv
              si
              indiferent daca acest motiv se afla sau nu sub controlul persoanei in cauza), sa primeasca Premiul
              (de exemplu: cont inchis, self-excluded etc.), Compania nu va plati valoarea Premiului respectiv in bani
              sau
              prin alte metode.
            </p>
          </li>
          <li class="conditions-list__item">
            <p>
              Premiul este netransferabil si nici nu poate in niciun caz fi acordat sau cedat niciunei alte persoane in
              afara de castigatorul respectiv.
            </p>
          </li>
          <li class="conditions-list__item">
            <p>
              Jucatorii inscrisi pot castiga Premiul numai o singura data, astfel incat locul aferent participarii va fi
              in mod automat acordat urmatorului clasat, in cazul in care un castigator anterior al unui Premiu a fost
              inlaturat in conditiile prezentului Regulament, al Termenilor si Conditiilor Generale sau ai legislatiei
              aplicabile.
            </p>
          </li>
          <li class="conditions-list__item">
            <p>
              In cazul in care din diferite considerente legale un Premiu nu poate fi acordat, acesta va fi anulat.
            </p>
          </li>
        </ol>
        <h3 class="conditions__subtitle">
          7. Termeni si conditii suplimentare
        </h3>
        <ol class="conditions-list m-b-20">
          <li class="conditions-list__item">
            <p>
              In caz de retragere a Premiilor, dupa indeplinirea conditiilor de rulaj, se vor aplica dispozitiile legale
              in vigoare cu privire la impozitarea castigurilor provenind din activitati de jocuri de noroc.
            </p>
          </li>
          <li class="conditions-list__item">
            <p>
              Jucatorii inscrisi sunt responsabili pentru orice inregistrari, depuneri sau plati catre orice autoritate
              guvernamentala sau de reglementare de orice fel cu privire la orice taxe sau impozite sau sume similare ce
              ar putea fi datorate, dupa caz, in legatura cu beneficiile primite conform prezentei Promotii, cu exceptia
              celor care conform legii revin Companiei.
            </p>
          </li>
          <li class="conditions-list__item">
            <p>
              Prin participarea la Promotie, fiecare Participant ne acorda noua (si oricarui tert contractor care ne
              furnizeaza servicii in legatura cu aceasta Promotie) dreptul de a folosi datele personale ale
              participantului in vederea facilitarii activitatii corepunzatoare aferente acestei Promotii.
            </p>
          </li>
          <li class="conditions-list__item">
            <p>
              In cazul inchiderii contului de joc al unuia dintre jucatorii participanti, sau in cazul auto-excluderii
              la orice moment dupa inscrierea in Promotie, acesta nu va mai fi eligibil pentru a primi Premiul sau
              pentru
              a participa in oricare dintre Activitatile Promotionale.
            </p>
          </li>
          <li class="conditions-list__item">
            <p>
              Ne rezervam dreptul sa anulam sau sa retragem beneficiile acordate in temeiul acestei Promotii la orice
              moment, fara nicio alta raspundere sau obligatie (pecuniara sau de alta natura) daca un jucator inscris
              face
              obiectul unei investigatii de catre o autoritate guvernamentala sau in cazul inchiderii / suspendarii
              contului sau de joc.
            </p>
          </li>
          <li class="conditions-list__item">
            <p>
              Toate prevederile prezentului regulament care, prin natura lor, supravietuiesc terminarii sau expirarii
              Promotiei pentru a-si produce efecte si a-si atinge scopul vor ramane in vigoare atat timp cat este
              necesar acestui scop.
            </p>
          </li>
        </ol>
        <h3 class="conditions__subtitle">
          8. Litigii
        </h3>
        <p>
          Orice disputa derivand din sau in legatura cu aceasta Promotie va fi solutionata prin intelegere amiabila
          intre
          Parti. In cazul in care partile nu reusesc sa ajunga la o astfel de intelegere amiabila, disputele vor fi
          deferite instantelor competente romane.
        </p>
        <h3 class="conditions__subtitle">
          9. Baza legală
        </h3>
        <p>
          Aceasta Promotie este organizata si se desfasoara cu respectarea prevederile O.U.G. 77/2009 si ale H.G.
          111/2016
          privind organizarea si exploatarea jocurilor de noroc.
        </p>
        <br>
        <a
          class="link-warning"
          href="docs/termeni_si_conditii.docx.pdf"
          target="_blank"
        >
          Descărcați Termeni și Condiții
        </a>
        <br>
      </div>
    </section>
    <section class="section">
      <h2 class="section__title">
        Providers
      </h2>
      <providers />
    </section>
    <div class="bg-gradient"></div>
  </div>
</template>

<script>
import Preloader from '@/components/Preloader';
import Providers from '@/components/Providers';
import Logo from '@/components/Logo';

export default {
  name: 'ConditionsVue',
  components: {
    Logo,
    Providers,
    preloader: Preloader,
  },
};
</script>
<style lang="scss">

.conditions-view {
  position: relative;
  background: url("../assets/img/bg/main-bg-1920.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  @media (max-width: 1920px) {
    background-size: contain;
    background-repeat: repeat;
    background-position: center top;
  }
}

.conditions {
  border-radius: 16px;
  background: rgba(33, 35, 73, 0.85);
  @media (max-width: 1680px) {
    width: calc(100% - 40px);
  }

  @include sm {
    margin: 0;
    width: 100%;
    border-radius: 0;
  }

  .tab-buttons {
    padding: 0 20px 20px;
    text-align: center;

    .btn {
      margin: 0 10px;

      &.btn__inner {
        margin: 5px 24px;
      }
    }
  }

  p {
    color: $white;
  }

  @extend %block-padding;
  max-width: 1380px;
  padding-left: 24px;
  padding-right: 24px;
  margin: 0 auto;
  color: #fff;
  @media (max-width: 576px) {
    padding-left: 10px;
    padding-right: 10px;
  }

  &__title {
    width: 100%;
    text-align: center;
    @extend %block-title;
    margin-bottom: 40px;
  }

  &__subtitle {
    font-size: 24px;
    margin-bottom: 20px;
  }

  &-list {
    padding-left: 25px;
    list-style: decimal-leading-zero;

    &__item {
      margin-bottom: 10px;
    }
  }

  li {
    padding-left: 5px;
    position: relative;
  }

  &__table {
    width: 100%;
    margin: 0 auto 5px;
    display: table;
    border-collapse: collapse;
    border-spacing: 0;
    color: $normal-white;
    text-align: center;
    overflow: hidden;

    td {
      border: 1px solid $secondary-color;
      padding: 10px;
    }
  }
}

.small-viewport {
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  margin: 30px 0 0 -30px;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: .25rem;
    height: 20px;
    background-color: rgba(255, 0, 50, .4);
  }

  &::-webkit-scrollbar-button {
    display: none
  }

  @media (max-width: 576px) {
    width: calc(100vw - 20px);
    padding: 0;
    overflow-x: auto;
    margin-left: -25px;
  }
  @media (max-width: 1280px) {
    padding: 0;
    overflow-x: auto;
    margin-left: -30px;
  }
}
</style>
